<template>
  <div class="panel-list">
    <div class="panel-item" v-for="(item,index) in list" :key="index">
      <div v-for="(columnItem,index1) in columns" :key="columnItem.label+index+index1" style="padding:3px 0px;">
        <span>{{columnItem.label}}：</span>
        <span>{{item[columnItem.dataIndex]}}</span>
      </div>
      <div class="panel-footer">
        <div v-for="btnItem in btnList" :key="btnItem.text" @click="btnItem.btnClick(item,index)">
          <img :src="btnItem.icon" style="width:20px">
          <span style="padding-left:3px">{{btnItem.text}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "panelItem",
  props:['list','columns','btnList'],
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.panel-item{
  box-shadow: 0px 0px 3px #dedede;
  margin:10px;
  background: #fff;
  border-radius: 5px;
  padding:15px;
  font-size: 16px;
  color: #333;
}
.panel-footer{
  border-top: 1px solid #dedede;
  margin-top:10px;
  display: flex;
  padding-top:8px;
  justify-content: end;
}
.panel-footer div{
  display: flex;
  margin:0px 8px;
}
</style>